<template>
        <v-data-table
          :headers="headers"
          :items="cdrs"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>SESSIONS</v-toolbar-title>
              <v-divider
              class="mx-4"
              inset
              vertical
              ></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
        <template v-slot:item="{ item }">
            <tr  class="tbl-hover">
              <td>{{ item.Id }}</td>
              <td><Cdr-Duration :start="item.SessionStart" :end="item.SessionEnd" /></td>
              <td class="text-center tableSmall">
                <v-icon small>mdi-timer</v-icon><span class="center-text">{{calcSessionDuration(item)}}</span><br />

                <v-icon small>mdi-flash</v-icon><span class="center-text">{{item.ConsumptionWh | toKW}}</span>
              </td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-credit-card</v-icon>
                  </template>
                  <span>{{ item.MspDescr }}</span>
                </v-tooltip>
                {{ item | getToken }}
              </td>
              <td>
              <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon :color="ttmpFixState(item.State)" dark v-on="on" v-if="item.State === 'ERROR'" class="didhedoit">mdi-alert-circle</v-icon>
                    <v-icon :color="ttmpFixState(item.State)" dark v-on="on" v-else>
                      mdi-checkbox-marked-circle</v-icon>
                  </template>
                  <span>{{ item.State | tmpFixState }}</span>
                </v-tooltip>
              </td>
              <td>
              <v-layout row>
                  <v-flex xs2>
                    <v-icon color="success">mdi-ev-station</v-icon>
                  </v-flex>
                  <v-flex xs10>
                    {{ item.ChargeLocationLabel }} | {{item.ChargePointName}}<br />{{ item.ChargeLocationStreet }} {{ item.ChargeLocationHouseNumber }}, {{ item.ChargeLocationZipCode }} {{ item.ChargeLocationCity }}
                  </v-flex>
                </v-layout>
              </td>
              <td>{{ item.TempOldCpmsConnNr | formatLA}}</td>
            </tr>
          </template>
        </v-data-table>
</template>

<script>
/* eslint-disable */
// import DateTimePicker from '@/components/shared/DateTimePicker'
// import GenericTablePagination from '@/components/shared/GenericTablePagination'
// import CdrDuration from '@/components/cdr/CdrDuration'
// import DropDownFilter from '../../components/filterV2/DropDownFilter'
// import CdrDetails from '../../components/cdr/CdrDetails'
// import {findToken} from '../../services/CommandService'
// import TokensQuickSearch from '../../components/quicksearch/TokensQuickSearch'
import moment from 'moment'
// import { hasRole } from '../../_core/functions/roles'
import CdrDuration from './CdrDuration'

export default {
  components: {
    // DateTimePicker,
    // GenericTablePagination,
    CdrDuration,
    // DropDownFilter,
    // CdrDetails,
    // TokensQuickSearch
  },
  data () {
    return {
      cdrStates: [
        {text: 'all', value: ''},
        {text: 'FINISHED', value: 'FINISHED'},
        {text: 'PARKING', value: 'PARKING'},
        {text: 'CHARGING', value: 'CHARGING'},
        {text: 'COMPLETED', value: 'COMPLETED'},
        {text: 'REJECTED', value: 'REJECTED'},
        {text: 'ERROR', value: 'ERROR'},
      ],
      searchFields: {
        Id: '',
        ChargeLocationLabel: '',
        TokenCode: '',
        SessionStart: {},
        SessionEnd: {},
        ChargePointId: '',
        State: ''
      },
      detailsDialog: false,
      selectedSession: null,
      tmpPagination: {},
      cdrs: [],
      panel: 0,
      filter: {},
      headers: [
        {text: 'Id', value: 'Id', sortable: false, align: 'left'},
        {text: 'Start/Stop session', value: 'startstop', sortable: false, align: 'left'},
        {text: 'Session duration', value: 'Duration', sortable: false, align: 'center'},
        // {text: 'Consumption', value: 'Consumption', sortable: false, align: 'left'},
        {text: 'Token', value: 'Token', sortable: false, align: 'left'},
        {text: 'Status', value: 'Status', sortable: false, align: 'left'},
        {text: 'Chargepoint', value: 'Chargepoint', sortable: false,},
        {text: 'Connector', value: 'Chargepoint', sortable: false, align: 'left'}
        // {text: 'Price', value: 'Price', sortable: false, align: 'left'}
      ],
      rowPerPage: [1, 25, 50],
      pagination: {
        CurrentPage: 1,
        MaxResults: 0,
        RowCount: 25,
        nextDisabled: true,
        prevDisabled: true,
        PageNumber: 1,
        stop: 0
      },
      loading: false
    }
  },
  mounted () {
    if (this.$vuetify.breakpoint.xs) {
      this.panel = [false]
    }
    if (this.$route.query.TokenCode) {
      this.searchFields.TokenCode = this.$route.query.TokenCode
      this.search()
      this.panel = [false]
    }
    if (this.$route.query.ChargePointId) {
      this.searchFields.ChargePointId = this.$route.query.ChargePointId
      this.search()
      this.panel = [false]
    }
    this.cdrs = [{
  Id: '1820285',
  ChargeLocationAddressAddon: '',
  ChargeLocationCity: 'Kortrijk',
  ChargeLocationCountry: 'BE',
  ChargeLocationFloor: '',
  ChargeLocationHouseNumber: '',
  ChargeLocationId: '',
  ChargeLocationLabel: 'BEALLEGO000918',
  ChargeLocationLatitude: '',
  ChargeLocationLongitude: '',
  ChargeLocationStreet: 'Pijlstraat 1',
  ChargeLocationZipCode: '8500',
  ChargePointId: '',
  ChargePointName: '',
  ConsumptionWh: 9000,
  CpoDescr: 'Allego',
  CpoIssuer: 'BEALL',
  CurrentSpeedW: '',
  EVSEId: '',
  EVSENumber: '',
  LastSignOfLife: '',
  MspDescr: 'BlueCorner',
  Pin: 6017,
  SessionEnd: '2020-06-29 12:21:58',
  SessionStart: '2020-06-29 09:18:37',
  State: 'FINISHED',
  StateDateTime: '2020-07-20 16:13:08',
  StateReason: 'UpdateEventFieldsFromRO',
  Street: 'Pijlstraat 1',
  TempOldCpmsConnNr: '',
  TokenCode: '03EE79BC',
  TokenEVCOId: 'BEBCOCBC403902',
  TokenExpirationDate: '',
  TokenMasterUID: '',
  TokenParentUID: '',
  TokenPrintedNumber: 'BE-BCA-403902-1',
  TokenType: 'RFID',
  TokenUID: 'BC79EE03',
  TotalRotationMinutes: 0
},
{
  Id: '1820402',
  ChargeLocationAddressAddon: '',
  ChargeLocationCity: 'Kortrijk',
  ChargeLocationCountry: 'BE',
  ChargeLocationFloor: '',
  ChargeLocationHouseNumber: '',
  ChargeLocationId: '',
  ChargeLocationLabel: 'BEALLEGO000918',
  ChargeLocationLatitude: '',
  ChargeLocationLongitude: '',
  ChargeLocationStreet: 'Pijlstraat 1',
  ChargeLocationZipCode: '8500',
  ChargePointId: '',
  ChargePointName: '',
  ConsumptionWh: 10000,
  CpoDescr: 'Allego',
  CpoIssuer: 'BEALL',
  CurrentSpeedW: '',
  EVSEId: '',
  EVSENumber: '',
  LastSignOfLife: '',
  MspDescr: 'BlueCorner',
  Pin: 9438,
  SessionEnd: '2020-06-27 18:58:33',
  SessionStart: '2020-06-27 13:02:33',
  State: 'FINISHED',
  StateDateTime: '2020-07-20 16:13:16',
  StateReason: 'UpdateEventFieldsFromRO',
  Street: 'Pijlstraat 1',
  TempOldCpmsConnNr: '',
  TokenCode: '03EE79BC',
  TokenEVCOId: 'BEBCOCBC403902',
  TokenExpirationDate: '',
  TokenMasterUID: '',
  TokenParentUID: '',
  TokenPrintedNumber: 'BE-BCA-403902-1',
  TokenType: 'RFID',
  TokenUID: 'BC79EE03',
  TotalRotationMinutes: 0
},
{
  Id: '1821247',
  ChargeLocationAddressAddon: '',
  ChargeLocationCity: 'Kortrijk',
  ChargeLocationCountry: 'BE',
  ChargeLocationFloor: '',
  ChargeLocationHouseNumber: '',
  ChargeLocationId: '',
  ChargeLocationLabel: 'BEALLEGO000917',
  ChargeLocationLatitude: '',
  ChargeLocationLongitude: '',
  ChargeLocationStreet: 'Senator Bossuytstraat 6',
  ChargeLocationZipCode: '8500',
  ChargePointId: '',
  ChargePointName: '',
  ConsumptionWh: 10000,
  CpoDescr: 'Allego',
  CpoIssuer: 'BEALL',
  CurrentSpeedW: '',
  EVSEId: '',
  EVSENumber: '',
  LastSignOfLife: '',
  MspDescr: 'BlueCorner',
  Pin: 7996,
  SessionEnd: '2020-06-16 16:25:35',
  SessionStart: '2020-06-16 08:24:46',
  State: 'FINISHED',
  StateDateTime: '2020-07-20 16:14:10',
  StateReason: 'UpdateEventFieldsFromRO',
  Street: 'Senator Bossuytstraat 6',
  TempOldCpmsConnNr: '',
  TokenCode: '03EE79BC',
  TokenEVCOId: 'BEBCOCBC403902',
  TokenExpirationDate: '',
  TokenMasterUID: '',
  TokenParentUID: '',
  TokenPrintedNumber: 'BE-BCA-403902-1',
  TokenType: 'RFID',
  TokenUID: 'BC79EE03',
  TotalRotationMinutes: 0
},
{
  Id: '1678570',
  ChargeLocationAddressAddon: '',
  ChargeLocationCity: 'Assebroek',
  ChargeLocationCountry: 'BE',
  ChargeLocationFloor: '',
  ChargeLocationHouseNumber: '',
  ChargeLocationId: '',
  ChargeLocationLabel: 'BEALLEGO004052',
  ChargeLocationLatitude: '',
  ChargeLocationLongitude: '',
  ChargeLocationStreet: 'Brugs-Kerkhofstraat 18',
  ChargeLocationZipCode: '8000',
  ChargePointId: '',
  ChargePointName: '',
  ConsumptionWh: 7000,
  CpoDescr: 'Allego',
  CpoIssuer: 'BEALL',
  CurrentSpeedW: '',
  EVSEId: '',
  EVSENumber: '',
  LastSignOfLife: '',
  MspDescr: 'BlueCorner',
  Pin: 1363,
  SessionEnd: '2020-05-20 13:56:05',
  SessionStart: '2020-05-20 08:45:45',
  State: 'FINISHED',
  StateDateTime: '2020-06-15 01:48:03',
  StateReason: 'UpdateEventFieldsFromRO',
  Street: 'Brugs-Kerkhofstraat 18',
  TempOldCpmsConnNr: '',
  TokenCode: '03EE79BC',
  TokenEVCOId: 'BEBCOCBC403902',
  TokenExpirationDate: '',
  TokenMasterUID: '',
  TokenParentUID: '',
  TokenPrintedNumber: 'BE-BCA-403902-1',
  TokenType: 'RFID',
  TokenUID: 'BC79EE03',
  TotalRotationMinutes: 0
},
{
  Id: '1678518',
  ChargeLocationAddressAddon: '',
  ChargeLocationCity: 'Kortrijk',
  ChargeLocationCountry: 'BE',
  ChargeLocationFloor: '',
  ChargeLocationHouseNumber: '',
  ChargeLocationId: '',
  ChargeLocationLabel: 'BEALLEGO000918',
  ChargeLocationLatitude: '',
  ChargeLocationLongitude: '',
  ChargeLocationStreet: 'Pijlstraat 1',
  ChargeLocationZipCode: '8500',
  ChargePointId: '',
  ChargePointName: '',
  ConsumptionWh: 7000,
  CpoDescr: 'Allego',
  CpoIssuer: 'BEALL',
  CurrentSpeedW: '',
  EVSEId: '',
  EVSENumber: '',
  LastSignOfLife: '',
  MspDescr: 'BlueCorner',
  Pin: 7543,
  SessionEnd: '2020-05-20 06:38:57',
  SessionStart: '2020-05-19 17:39:46',
  State: 'FINISHED',
  StateDateTime: '2020-06-15 01:47:58',
  StateReason: 'UpdateEventFieldsFromRO',
  Street: 'Pijlstraat 1',
  TempOldCpmsConnNr: '',
  TokenCode: '03EE79BC',
  TokenEVCOId: 'BEBCOCBC403902',
  TokenExpirationDate: '',
  TokenMasterUID: '',
  TokenParentUID: '',
  TokenPrintedNumber: 'BE-BCA-403902-1',
  TokenType: 'RFID',
  TokenUID: 'BC79EE03',
  TotalRotationMinutes: 0
},
{
  Id: '1640907',
  ChargeLocationAddressAddon: '',
  ChargeLocationCity: 'Kortrijk',
  ChargeLocationCountry: 'BE',
  ChargeLocationFloor: '',
  ChargeLocationHouseNumber: '',
  ChargeLocationId: '',
  ChargeLocationLabel: 'BEALLEGO000918',
  ChargeLocationLatitude: '',
  ChargeLocationLongitude: '',
  ChargeLocationStreet: 'Pijlstraat 1',
  ChargeLocationZipCode: '8500',
  ChargePointId: '',
  ChargePointName: '',
  ConsumptionWh: 10000,
  CpoDescr: 'Allego',
  CpoIssuer: 'BEALL',
  CurrentSpeedW: '',
  EVSEId: '',
  EVSENumber: '',
  LastSignOfLife: '',
  MspDescr: 'BlueCorner',
  Pin: 5500,
  SessionEnd: '2020-03-16 07:32:35',
  SessionStart: '2020-03-15 16:54:58',
  State: 'FINISHED',
  StateDateTime: '2020-05-12 03:34:08',
  StateReason: 'UpdateEventFieldsFromRO',
  Street: 'Pijlstraat 1',
  TempOldCpmsConnNr: '',
  TokenCode: '03EE79BC',
  TokenEVCOId: 'BEBCOCBC403902',
  TokenExpirationDate: '',
  TokenMasterUID: '',
  TokenParentUID: '',
  TokenPrintedNumber: 'BE-BCA-403902-1',
  TokenType: 'RFID',
  TokenUID: 'BC79EE03',
  TotalRotationMinutes: 0
}]
  },
  methods: {
    ttmpFixState (val) {
      if (val === 'COMPLETED') {
        return 'FINISHED'
      }
      return val
    },
    checkHasRole () {
      let rtn = !hasRole('Cpo')
      return true
    },
    clickedRow (e) {
      this.selectedSession = e
      this.$nextTick(() => {
        this.detailsDialog = true
      })
      // this.$router.push({name: 'cdrDetails', params: {id: e}})
    },
    test () {
      let t = {
        "PageNumber": 1,
        "MaxResults": 250,
        "PageCount": 3,
        "Results": 67,
        "RowCount": 25,
        "ResultFull": 1
      }
      this.$refs.pagination.genPagination(t)
    },
    calcSessionDuration (obj) {
      let start = moment(obj.SessionStart)
      let stop = null
      if (!obj.SessionEnd) {
        stop = moment(new Date())
      } else {
        stop = moment(obj.SessionEnd)
      }
      let stuff = moment((moment.duration(stop.diff(start)))).format(`D [d], HH[h]mm[m]`)
      return stuff
    },
    async fetchSessions () {
      let select = {"DataCategories":["BASIC","ADMIN","STATE"],"Fields":["ChargeLocationCity","ChargeLocationCountry","ChargeLocationAddressAddon","ChargeLocationFloor","ChargeLocationHouseNumber","ChargeLocationId","ChargeLocationLabel","ChargeLocationLatitude","ChargeLocationLongitude","ChargeLocationStreet","ChargeLocationZipCode","ChargePointName","ChargePointId","ConsumptionWh","CpoDescr","CpoIssuer","CurrentSpeedW","MspDescr","EVSEId","EVSENumber","LastSignOfLife","Pin","SessionEnd","SessionStart","State","StateDateTime","StateReason","Street","TokenCode","TokenEVCOId","TokenExpirationDate","TokenMasterUID","TokenParentUID","TokenPrintedNumber","TokenType","TokenUID","TotalRotationMinutes", "TempOldCpmsConnNr", "State", "StateReason"]}
      let obj = Object.assign({}, this.searchFields)
      // if (obj.TokenPrintedNumber !== '') {
      //   let token = await findToken({tokenprint: obj.TokenPrintedNumber})
      //   console.log(token)
      //   obj.TokenCode = token.Data.Token.Code
      //   delete obj.TokenPrintedNumber
      // }
      let filter = this.$BaseApi.generateFilter(obj)
      this.loading = true
      let dt = await new this.$BaseApi('ChargeSession', 0, this.$store.getters['oidcStore/oidcAccessToken']).filter(filter, {pagesize: this.pagination.RowCount, pagenumber: this.pagination.PageNumber, orderby: JSON.stringify(["SessionStart desc"])}, select)
      this.pagination = dt.Page
      this.loading = false
      this.cdrs = {
  Id: '1820285',
  ChargeLocationAddressAddon: '',
  ChargeLocationCity: 'Kortrijk',
  ChargeLocationCountry: 'BE',
  ChargeLocationFloor: '',
  ChargeLocationHouseNumber: '',
  ChargeLocationId: '',
  ChargeLocationLabel: 'BEALLEGO000918',
  ChargeLocationLatitude: '',
  ChargeLocationLongitude: '',
  ChargeLocationStreet: 'Pijlstraat 1',
  ChargeLocationZipCode: '8500',
  ChargePointId: '',
  ChargePointName: '',
  ConsumptionWh: 9000,
  CpoDescr: 'Allego',
  CpoIssuer: 'BEALL',
  CurrentSpeedW: '',
  EVSEId: '',
  EVSENumber: '',
  LastSignOfLife: '',
  MspDescr: 'BlueCorner',
  Pin: 6017,
  SessionEnd: '2020-06-29 12:21:58',
  SessionStart: '2020-06-29 09:18:37',
  State: 'FINISHED',
  StateDateTime: '2020-07-20 16:13:08',
  StateReason: 'UpdateEventFieldsFromRO',
  Street: 'Pijlstraat 1',
  TempOldCpmsConnNr: '',
  TokenCode: '03EE79BC',
  TokenEVCOId: 'BEBCOCBC403902',
  TokenExpirationDate: '',
  TokenMasterUID: '',
  TokenParentUID: '',
  TokenPrintedNumber: 'BE-BCA-403902-1',
  TokenType: 'RFID',
  TokenUID: 'BC79EE03',
  TotalRotationMinutes: 0
},
{
  Id: '1820402',
  ChargeLocationAddressAddon: '',
  ChargeLocationCity: 'Kortrijk',
  ChargeLocationCountry: 'BE',
  ChargeLocationFloor: '',
  ChargeLocationHouseNumber: '',
  ChargeLocationId: '',
  ChargeLocationLabel: 'BEALLEGO000918',
  ChargeLocationLatitude: '',
  ChargeLocationLongitude: '',
  ChargeLocationStreet: 'Pijlstraat 1',
  ChargeLocationZipCode: '8500',
  ChargePointId: '',
  ChargePointName: '',
  ConsumptionWh: 10000,
  CpoDescr: 'Allego',
  CpoIssuer: 'BEALL',
  CurrentSpeedW: '',
  EVSEId: '',
  EVSENumber: '',
  LastSignOfLife: '',
  MspDescr: 'BlueCorner',
  Pin: 9438,
  SessionEnd: '2020-06-27 18:58:33',
  SessionStart: '2020-06-27 13:02:33',
  State: 'FINISHED',
  StateDateTime: '2020-07-20 16:13:16',
  StateReason: 'UpdateEventFieldsFromRO',
  Street: 'Pijlstraat 1',
  TempOldCpmsConnNr: '',
  TokenCode: '03EE79BC',
  TokenEVCOId: 'BEBCOCBC403902',
  TokenExpirationDate: '',
  TokenMasterUID: '',
  TokenParentUID: '',
  TokenPrintedNumber: 'BE-BCA-403902-1',
  TokenType: 'RFID',
  TokenUID: 'BC79EE03',
  TotalRotationMinutes: 0
},
{
  Id: '1821247',
  ChargeLocationAddressAddon: '',
  ChargeLocationCity: 'Kortrijk',
  ChargeLocationCountry: 'BE',
  ChargeLocationFloor: '',
  ChargeLocationHouseNumber: '',
  ChargeLocationId: '',
  ChargeLocationLabel: 'BEALLEGO000917',
  ChargeLocationLatitude: '',
  ChargeLocationLongitude: '',
  ChargeLocationStreet: 'Senator Bossuytstraat 6',
  ChargeLocationZipCode: '8500',
  ChargePointId: '',
  ChargePointName: '',
  ConsumptionWh: 10000,
  CpoDescr: 'Allego',
  CpoIssuer: 'BEALL',
  CurrentSpeedW: '',
  EVSEId: '',
  EVSENumber: '',
  LastSignOfLife: '',
  MspDescr: 'BlueCorner',
  Pin: 7996,
  SessionEnd: '2020-06-16 16:25:35',
  SessionStart: '2020-06-16 08:24:46',
  State: 'FINISHED',
  StateDateTime: '2020-07-20 16:14:10',
  StateReason: 'UpdateEventFieldsFromRO',
  Street: 'Senator Bossuytstraat 6',
  TempOldCpmsConnNr: '',
  TokenCode: '03EE79BC',
  TokenEVCOId: 'BEBCOCBC403902',
  TokenExpirationDate: '',
  TokenMasterUID: '',
  TokenParentUID: '',
  TokenPrintedNumber: 'BE-BCA-403902-1',
  TokenType: 'RFID',
  TokenUID: 'BC79EE03',
  TotalRotationMinutes: 0
},
{
  Id: '1678570',
  ChargeLocationAddressAddon: '',
  ChargeLocationCity: 'Assebroek',
  ChargeLocationCountry: 'BE',
  ChargeLocationFloor: '',
  ChargeLocationHouseNumber: '',
  ChargeLocationId: '',
  ChargeLocationLabel: 'BEALLEGO004052',
  ChargeLocationLatitude: '',
  ChargeLocationLongitude: '',
  ChargeLocationStreet: 'Brugs-Kerkhofstraat 18',
  ChargeLocationZipCode: '8000',
  ChargePointId: '',
  ChargePointName: '',
  ConsumptionWh: 7000,
  CpoDescr: 'Allego',
  CpoIssuer: 'BEALL',
  CurrentSpeedW: '',
  EVSEId: '',
  EVSENumber: '',
  LastSignOfLife: '',
  MspDescr: 'BlueCorner',
  Pin: 1363,
  SessionEnd: '2020-05-20 13:56:05',
  SessionStart: '2020-05-20 08:45:45',
  State: 'FINISHED',
  StateDateTime: '2020-06-15 01:48:03',
  StateReason: 'UpdateEventFieldsFromRO',
  Street: 'Brugs-Kerkhofstraat 18',
  TempOldCpmsConnNr: '',
  TokenCode: '03EE79BC',
  TokenEVCOId: 'BEBCOCBC403902',
  TokenExpirationDate: '',
  TokenMasterUID: '',
  TokenParentUID: '',
  TokenPrintedNumber: 'BE-BCA-403902-1',
  TokenType: 'RFID',
  TokenUID: 'BC79EE03',
  TotalRotationMinutes: 0
},
{
  Id: '1678518',
  ChargeLocationAddressAddon: '',
  ChargeLocationCity: 'Kortrijk',
  ChargeLocationCountry: 'BE',
  ChargeLocationFloor: '',
  ChargeLocationHouseNumber: '',
  ChargeLocationId: '',
  ChargeLocationLabel: 'BEALLEGO000918',
  ChargeLocationLatitude: '',
  ChargeLocationLongitude: '',
  ChargeLocationStreet: 'Pijlstraat 1',
  ChargeLocationZipCode: '8500',
  ChargePointId: '',
  ChargePointName: '',
  ConsumptionWh: 7000,
  CpoDescr: 'Allego',
  CpoIssuer: 'BEALL',
  CurrentSpeedW: '',
  EVSEId: '',
  EVSENumber: '',
  LastSignOfLife: '',
  MspDescr: 'BlueCorner',
  Pin: 7543,
  SessionEnd: '2020-05-20 06:38:57',
  SessionStart: '2020-05-19 17:39:46',
  State: 'FINISHED',
  StateDateTime: '2020-06-15 01:47:58',
  StateReason: 'UpdateEventFieldsFromRO',
  Street: 'Pijlstraat 1',
  TempOldCpmsConnNr: '',
  TokenCode: '03EE79BC',
  TokenEVCOId: 'BEBCOCBC403902',
  TokenExpirationDate: '',
  TokenMasterUID: '',
  TokenParentUID: '',
  TokenPrintedNumber: 'BE-BCA-403902-1',
  TokenType: 'RFID',
  TokenUID: 'BC79EE03',
  TotalRotationMinutes: 0
},
{
  Id: '1640907',
  ChargeLocationAddressAddon: '',
  ChargeLocationCity: 'Kortrijk',
  ChargeLocationCountry: 'BE',
  ChargeLocationFloor: '',
  ChargeLocationHouseNumber: '',
  ChargeLocationId: '',
  ChargeLocationLabel: 'BEALLEGO000918',
  ChargeLocationLatitude: '',
  ChargeLocationLongitude: '',
  ChargeLocationStreet: 'Pijlstraat 1',
  ChargeLocationZipCode: '8500',
  ChargePointId: '',
  ChargePointName: '',
  ConsumptionWh: 10000,
  CpoDescr: 'Allego',
  CpoIssuer: 'BEALL',
  CurrentSpeedW: '',
  EVSEId: '',
  EVSENumber: '',
  LastSignOfLife: '',
  MspDescr: 'BlueCorner',
  Pin: 5500,
  SessionEnd: '2020-03-16 07:32:35',
  SessionStart: '2020-03-15 16:54:58',
  State: 'FINISHED',
  StateDateTime: '2020-05-12 03:34:08',
  StateReason: 'UpdateEventFieldsFromRO',
  Street: 'Pijlstraat 1',
  TempOldCpmsConnNr: '',
  TokenCode: '03EE79BC',
  TokenEVCOId: 'BEBCOCBC403902',
  TokenExpirationDate: '',
  TokenMasterUID: '',
  TokenParentUID: '',
  TokenPrintedNumber: 'BE-BCA-403902-1',
  TokenType: 'RFID',
  TokenUID: 'BC79EE03',
  TotalRotationMinutes: 0
}
    },
    search () {
      this.fetchSessions()
    },
    setPaginationOut (data) {
      this.paginationOut = data
      this.search()
    },
    getDetails (id) {
      console.log(id)
    }
  },
  filters: {
    formatLA(text) {
      return text !== undefined && text !== '' ? text : ' - '
    },
    tmpFixState (val) {
      if (val === 'COMPLETED') {
        return 'FINISHED'
      }
      return val
    },
    toKW (val) {
      let calc = parseInt(val) / 10
      calc = Math.ceil(calc) / 100
      return calc + ' kWh'
    },
    getToken (val) {
      if (val.TokenPrintedNumber) {
        return val.TokenPrintedNumber
      } else if (val.TokenEVCOId) {
        return val.TokenEVCOId
      } else {
        return val.TokenCode
      }
    }
  },
  props: ['label']
}
</script>

<style lang="scss">
// @import "../../scss/cdrs/Colors.scss";
</style>

<style scoped>
.par {
  position: relative;
}

/* .ch {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */
.tbl-hover {
  cursor: pointer;
}
</style>
