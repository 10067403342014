<template>
  <div>
    <p class="text-center" v-if="!end"><b>{{_startTime}}</b><br />{{_startDay}}</p>
    <p class="text-center" v-else-if="_sameDay && end"><b>{{_startTime}} - {{_endTime}}</b><br />{{_startDay}}</p>
    <p class="text-center" v-else-if="!_sameDay && end"><b>{{_startTime}} - {{_endTime}}</b><br />{{_startDayShort}} - {{_endDayShort}}</p>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: ['start', 'end'],
  computed: {
    _startDay () {
      return moment(this.start).format('DD MMM YYYY')
    },
    _endDay () {
      return moment(this.end).format('DD MMM YYYY')
    },
    _startDayShort () {
      return moment(this.start).format('DD/MM/YY')
    },
    _endDayShort () {
      return moment(this.end).format('DD/MM/YY')
    },
    _sameDay () {
      if (this._startDay === this._endDay) {
        return true
      }
      return false
    },
    _startTime () {
      return moment(this.start).format('HH:mm')
    },
    _endTime () {
      return moment(this.end).format('HH:mm')
    }
  }
}
</script>

<style scoped>
  div {
    max-width: 150px;
    min-width: 110px;
  }
  p {
    margin: 5px;
  }
</style>
